/* Reset css */

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ul {
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select,
button,
fieldset {
  vertical-align: middle;
  border: 0;
  outline: 0;
  background: transparent;
  border-radius: 0;
  color: inherit;
  padding: 0;
  margin: 0;
}

button,
input[type='button'],
input[type='submit'],
input[type='reset'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

a,
span,
i,
strong {
  font-style: normal;
  display: inline-block;
}
img,
video {
  max-width: 100%;
}
/* svg { width: 100%;} */

.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
// .stop-drag {
//   -webkit-user-select: none;
// }

body {
  touch-action: pan-y; /* 최신 브라우저용 */
  /* -ms-touch-action: pan-y; 구형 브라우저용, 필요할 경우 추가 */
}
